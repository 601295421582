<template>
  <div :class="{'phone': mq.phone}">
    <div data-test="newsEditModal">
      <Window
        :modal="true"
        class="news-edit-modal"
        @close="onClose"
        @keydown.exact.backspace.stop=""
        @keydown.exact.delete.stop=""
        @keydown.exact.escape.stop="onClose"
      >
        <template #header>
          <span
            v-if="isEvent"
            v-t="'News.NewsEditModal.headerEvent'"
          />
          <span
            v-else
            v-t="'News.NewsEditModal.headerNews'"
          />
        </template>

        <template #body>
          <section class="title-section">
            <div
              class="indicator-picture"
              @click="openImagePickerModal"
            >
              <div
                v-if="thumbnailUrl === undefined"
                class="picture-placeholder"
              />
              <img
                v-else
                class="thumbnail"
                :src="thumbnailUrl"
                alt="Object thumbnail"
              >
              <span v-t="'News.NewsEditModal.imageLabel'" />
            <!-- <div class="error-message">
              {{ error.picture }}
            </div> -->
            </div>

            <div class="inputs">
              <!-- Title -->
              <div class="form-group">
                <label v-t="'News.NewsEditModal.titleLabel'" />
                <Input
                  id="news-edit-title-input"
                  v-model="news.title"
                  class="title-input"
                  data-test="title-input"
                  :placeholder="isEvent ? $t('News.NewsEditModal.titleEventPlaceholder') : $t('News.NewsEditModal.titlePlaceholder')"
                />
              </div>

              <div class="form-group">
                <label v-t="'News.NewsEditModal.releaseDateLabel'" />
                <Datepicker
                  v-model="formattedReleaseDate"
                  input-format="dd/MM/yy"
                  :locale="locale"
                  class="base-input"
                />
              </div>
            </div>
          </section>

          <div
            v-if="isEvent"
            class="form-group"
          >
            <label v-t="'News.NewsEditModal.eventDateLabel'" />
            <Datepicker
              v-model="formattedEventDate"
              input-format="dd/MM/yy"
              :locale="locale"
              class="base-input"
            />
          </div>
          <div
            v-if="isEvent"
            class="form-group"
          >
            <label v-t="'News.NewsEditModal.locationLabel'" />
            <Input
              v-model="news.location"
              :placeholder="$t('News.NewsEditModal.locationPlaceholder')"
            />
          </div>

          <!-- Content -->
          <div class="form-group">
            <label v-t="'News.NewsEditModal.contentLabel'" />
            <textarea
              v-model="news.content"
              class="content base-input"
              :placeholder="isEvent ? $t('News.NewsEditModal.contentEventPlaceholder') : $t('News.NewsEditModal.contentPlaceholder')"
            />
          </div>

          <div class="form-group">
            <label v-t="'News.NewsEditModal.linkDescriptionLabel'" />
            <Input
              v-model="news.linkDescription"
              :placeholder="$t('News.NewsEditModal.linkDescriptionPlaceholder')"
            />
          </div>
          <div class="form-group">
            <label v-t="'News.NewsEditModal.linkUrlLabel'" />
            <Input
              v-model="news.linkUrl"
              :placeholder="$t('News.NewsEditModal.linkUrlPlaceholder')"
            />
          </div>
        </template>

        <template #footer>
          <Button
            data-test="cancelButton"
            class="cancelButton"
            :label="$t('News.NewsEditModal.cancelButton')"
            @click="onClose"
          />
          <Button
            data-test="submitButton"
            class="dark"
            :label="$t('News.NewsEditModal.submitButton')"
            @click="save"
          />
        </template>
      </Window>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import Datepicker from 'vue3-datepicker'
import { fr } from 'date-fns/locale'

const isTitleValid = (str) => {
  return !(str.trim() === '') // Subject must not contain only spaces
}

export default {
  name: 'NewsEditModal',
  components: {
    Datepicker
  },
  inject: ['mq'],
  props: {
    editedNews: {
      type: Object,
      default: undefined
    },
    isEvent: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      locale: fr,
      news: {
        title: '',
        releaseDate: dayjs().format('YYYY-MM-DD'),
        eventDate: dayjs().format('YYYY-MM-DD'),
        location: '',
        content: '',
        linkDescription: '',
        linkUrl: '',
        thumbnailBlob: undefined
      }
    }
  },
  validations: {
    title: {
      required,
      isTitleValid
    }
  },
  computed: {
    formattedEventDate: {
      get () {
        return dayjs(this.news.eventDate).toDate()
      },
      set (date) {
        this.news.eventDate = dayjs(date).format('YYYY-MM-DD')
      }
    },
    formattedReleaseDate: {
      get () {
        return dayjs(this.news.releaseDate).toDate()
      },
      set (date) {
        this.news.releaseDate = dayjs(date).format('YYYY-MM-DD')
      }
    },
    thumbnailUrl () {
      return this.news.thumbnailBlob ? URL.createObjectURL(this.news.thumbnailBlob) : this.news.thumbnail
    }
  },
  mounted () {
    if (this.editedNews !== undefined) {
      this.news = JSON.parse(JSON.stringify(this.editedNews))
    }
    this.date = dayjs(this.news.releaseDate).toDate()
  },
  methods: {
    openImagePickerModal () {
      this.$store.dispatch('modals/openImagePickerModal', { onConfirm: this.onPictureSave, dimensions: { ratio: 3 / 2, width: 150, height: 100 } })
    },
    onPictureSave (imageBlob) {
      this.news.thumbnailBlob = imageBlob
    },
    editEvent () {
      if (this.news.eventId !== undefined) {
        // Update
        this.$store.dispatch('event/updateEvent', this.news)
      } else {
        // Create
        this.$store.dispatch('event/addEvent', this.news)
      }
    },
    editNews () {
      if (this.news.newsId !== undefined) {
        // Update
        this.$store.dispatch('news/updateNews', this.news)
      } else {
        // Create
        this.$store.dispatch('news/addNews', this.news)
      }
    },
    onClose () {
      this.$emit('close')
    },
    save () {
      if (this.isEvent) {
        this.editEvent()
      } else {
        this.editNews()
      }
      this.onClose()
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: #0B3C5F;
}

.title-section {
  display: flex;
}

.indicator-picture {
  margin-right: 0.5rem;
  text-align: center;
  color: #0B3C5F;
  cursor: pointer;

  span {
    opacity: 0.8;
  }
}

.picture-placeholder, .thumbnail {
  width: 200px;
  height: 133px;
  border: 1px solid #D9E2EA;
  border-radius: 6px;
}

.inputs {
  width: 100%;
}

.news-edit-modal {
  padding: 20px;
  display: flex;

  .form-group {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
  }

  .content {
    width: 100%;
    min-width: 30rem;
    min-height: 7rem;
  }

  button {
    margin-left: 10px;
  }

}

.phone {
  .news-edit-modal {
    padding: 0;
    display: initial;
  }

  .title-section {
    flex-direction: column;

    .indicator-picture span {
      display: block;
    }
  }

  .picture-placeholder, .thumbnail {
    width: 150px;
    height: 100px;
  }

  .content {
    min-width: 100%;
  }
}
</style>

<style>
.phone .news-edit-modal .window-body {
  overflow: auto;
}
</style>
