<template>
  <div
    class="add-news"
    :class="device + '-add-news'"
  >
    <div
      class="img-container"
      @click.stop="openCreateModal"
    >
      <img
        src="@assets/icon_indicateur_ajout.svg"
        alt="add-news"
      >
    </div>
    <p
      v-if="isEvent"
      v-t="'News.addEvent'"
      @click.stop="openCreateModal"
    />
    <p
      v-else
      v-t="'News.addNews'"
      @click.stop="openCreateModal"
    />
  </div>
</template>

<script>

export default {
  name: 'AddNewsItem',
  inject: ['mq'],
  props: {
    isEvent: {
      type: Boolean,
      default: false
    }
  },
  emits: ['openCreateModal'],
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    }
  },
  methods: {
    openCreateModal () {
      this.$emit('openCreateModal', undefined)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.add-news {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: $color-cadyco-dark-text;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;

  p {
    width: 100%;
    margin-top: 0.8rem;
  }

  .img-container {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 100px;
    border: 1px solid silver;
    border-radius: 6px;

    .img {
      height: 25px;
    }
  }

  .popover {
    position: absolute;
    top: 84px;
    right: 0;
  }

  &:hover {
    color: $color-hover-cadyco;
    .img-container {
      border-color: $color-hover-cadyco;
    }
  }
}

.phone-add-news {
  flex-direction: row;
  position: relative;

  .img-container {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }

  p {
    width: auto;
  }

  .popover {
    position: absolute;
    top: 0;
    left: -20px;
  }
}

</style>
