<template>
  <div
    class="news-item"
    :class="device + '-news'"
  >
    <div class="row1">
      <div
        class="column1"
        :class="device + '-column1'"
      >
        <div
          class="image-container"
        >
          <img
            class="image"
            :class="{'hover': isHovering}"
            :src="news.thumbnail"
            alt="img"
          >
        </div>
      </div>

      <div class="column2">
        <div
          class="options"
        >
          <button
            :title="$t('ContextMenuOptions.delete')"
            class="option"
            @click="throwWarning"
          >
            <img
              :src="require('@assets/icon_trash.svg')"
              alt="trash"
            >
          </button>
          <button
            :title="$t('ContextMenuOptions.modify')"
            class="option"
            @click="updateNews"
          >
            <img
              :src="require('@assets/options/icon_edit_texte.svg')"
              alt="update"
            >
          </button>
          <span>le {{ formattedDate }}</span>
        </div>
      </div>
    </div>
    <p>
      {{ news.title }}
    </p>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'NewsItem',
  inject: ['mq'],
  props: {
    isEvent: {
      type: Boolean,
      default: false
    },
    news: {
      type: Object,
      required: true
    }
  },
  emits: ['openUpdateModal', 'remove'],
  data () {
    return {
      isHovering: false
    }
  },
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    },
    formattedDate () {
      return moment(this.news.releaseDate, 'YYYY-MM-DD').format('DD/MM/YY')
    }
  },
  methods: {
    updateNews () {
      this.$emit('openUpdateModal', this.news)
    },
    deleteNews () {
      if (this.isEvent) {
        this.$store.dispatch('event/deleteEvent', this.news.eventId)
      } else {
        this.$store.dispatch('news/deleteNews', this.news.newsId)
      }
    },
    throwWarning () {
      this.$store.dispatch('warningModal/addWarning', {
        text: this.$t('News.Warning.deleteNews'),
        lastAction: { fct: this.deleteNews }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.news-item {
  color: $color-cadyco-dark-text;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;

  p {
    margin-top: 0.5rem;
  }
}

.row1 {
  display: flex;
}

.options {
  padding-left: 6px;
  padding-right: 8px;
  padding-top: 3px;
  display: flex;
  flex-direction: column;

  .option {
    margin-bottom: 7px;
    background: white;
    height: 33px;
    width: 33px;
    border: none;
    border-radius: 17px;
    cursor: pointer;

    &:hover {
      background-color: $color-hover-cadyco;
    }
  }
}

.image-container {
  position: relative;
  text-align: center;
  background-color: white;

  .image {
    display: block;
    width: 150px;
    height: 100px;
    border-radius: 6px;
    border: 1px solid transparent;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);
  }

}

.phone-news {
  width: 100%;
  margin-bottom: 10px;

  .phone-column1 {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .image {
    width: 100px;
    height: 66px;
  }

  .column2 {
    display: flex;
    align-items: center;
    justify-content: center;

    .options {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
