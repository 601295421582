<template>
  <div class="content">
    <div
      :class="device + '-news-list'"
    >
      <NewsItem
        v-for="(news, index) in newsList"
        :key="news.newsId || news.eventId"
        :index="index"
        :news="news"
        :is-event="isEvent"
        @openUpdateModal="openUpdateModal"
      />
      <AddNewsItem
        :is-event="isEvent"
        @openCreateModal="openUpdateModal"
      />
    </div>
    <teleport to="body">
      <NewsEditModal
        v-if="displayNewsEditModal"
        :edited-news="editedNews"
        :is-event="isEvent"
        @close="displayNewsEditModal = false"
      />
    </teleport>
  </div>
</template>

<script>
import NewsItem from '@components/News/NewsItem'
import NewsEditModal from '@components/News/NewsEditModal'
import AddNewsItem from '@components/News/AddNewsItem'

export default {
  name: 'NewsList',
  components: {
    NewsEditModal,
    NewsItem,
    AddNewsItem
  },
  inject: ['mq'],
  props: {
    isEvent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      displayNewsEditModal: false
    }
  },
  computed: {
    newsList () {
      return this.isEvent ? this.$store.getters['event/sortedEventList'] || [] : this.$store.getters['news/sortedNewsList'] || []
    },
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    }
  },
  mounted () {
    if (this.$store.state.news.newsList === undefined && !this.isEvent) {
      this.$store.dispatch('news/initNewsList')
    } else {
      this.$store.dispatch('event/initEventList')
    }
  },
  methods: {
    openUpdateModal (news) {
      this.editedNews = news
      this.displayNewsEditModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.content {
  background-color: $dark-white-bg;
  padding: 20px 20px 30px 20px;
}

.desktop-news-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  gap: 15px 10px;
}

.phone-news-list {
  display: flex;
  flex-direction: column;
}
</style>
