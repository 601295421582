<template>
  <AuthenticatedLayout v-if="isAdministrator || isCadycoAdministrator || isCommunicationManager">
    <div
      class="container"
    >
      <h3
        v-t="'News.title'"
        class="header"
      />
      <NewsList />
      <h3
        v-t="'News.eventTitle'"
        class="header"
      />
      <NewsList :is-event="true" />
    </div>
  </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@layouts/AuthenticatedLayout'
import NewsList from '@components/News/NewsList'

export default {
  name: 'NewsManager',
  components: {
    AuthenticatedLayout,
    NewsList
  },
  data: function () {
    return {
    }
  },
  computed: {
    isAdministrator () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.isAdministrator
      }
      this.$store.dispatch('auth/getUserInfos')
      return false
    },
    isCadycoAdministrator () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.isCadycoAdministrator
      }
      this.$store.dispatch('auth/getUserInfos')
      return false
    },
    isCommunicationManager () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.isCommunicationManager
      }
      this.$store.dispatch('auth/getUserInfos')
      return false
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
 @import '@design';

.container {
  position: relative;
  height: calc(100% - #{$desktop-search-bar-height});
  overflow: auto;
  //padding: 30px 20px 0 20px;
}

.catalog-manager {
  display: flex;
}

.header {
  margin-left: 10px;
}

.roles {
  width: 30%;
  border-right: 1px solid black;
}

.catalog {
  width: 70%;
  padding-left: 15px;
}

a{
  cursor: pointer;
}
</style>
